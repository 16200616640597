.ad_wrapper_w1200 {
  margin: 0 auto;
  max-width: 1200px;

}

.ad_wrapper_w1030 {
  margin: 0 auto;
  max-width: 1030px;

}

.branding_l_r {
  align-items: flex-start;
  bottom: 0;
  top: 349px;
  display: flex;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  &.pixel{
    top: 517px;
  }
}

.branding_top_w1200 {
  cursor: pointer;
  position: relative;
  z-index: 10;
  width: 1200px;
  height: 200px;
  display: block;
  text-align: center;
  background-color: #F7F7FC;
  transition: background-color 250ms ease;
  &:hover {
    background-color: #E2EAF5;
  }
}

.branding_top_w1030 {
  cursor: pointer;
  position: relative;
  z-index: 10;
  width: 1030px;
  height: 200px;
  display: block;
  text-align: center;
  background-color: #F7F7FC;
  transition: background-color 250ms ease;
  &:hover {
    background-color: #E2EAF5;
  }
}
.branding_video {
  position: absolute;
  cursor: pointer;
  z-index: 20;
  width: 320px;
  height: 180px;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-left: 16px;
  background-color: #F7F7FC;
}

.branding_left_w1200 {
  cursor: pointer;
  z-index: 20;
  width: 160px;
  height: 600px;
  flex: 0 0 auto;
  margin: 0;
  top: 0px;
  right: 600px;
  transform: translateX(-600px);
  pointer-events: initial;
  position: sticky;
  display: block;
  background-color: #F7F7FC;
  transition: background-color 250ms ease;
  &:hover {
    background-color: #E2EAF5;
  }

}

.branding_right_w1200 {
  cursor: pointer;
  z-index: 20;
  width: 160px;
  height: 600px;
  flex: 0 0 auto;
  margin: 0;
  top: 0px;
  left: 600px;
  transform: translateX(600px);
  pointer-events: initial;
  position: sticky;
  display: block;
  background-color: #F7F7FC;
  transition: background-color 250ms ease;
  &:hover {
    background-color: #E2EAF5;
  }
}

.branding_left_w1030 {
  cursor: pointer;
  z-index: 20;
  width: 160px;
  height: 600px;
  flex: 0 0 auto;
  margin: 0;
  top: 0px;
  right: 515px;
  transform: translateX(-515px);
  pointer-events: initial;
  position: sticky;
  display: block;
  background-color: #F7F7FC;
  transition: background-color 250ms ease;
  &:hover {
    background-color: #E2EAF5;

  }
}

.branding_right_w1030 {
  cursor: pointer;
  z-index: 20;
  width: 160px;
  height: 600px;
  flex: 0 0 auto;
  margin: 0;
  top: 0px;
  left: 515px;
  transform: translateX(515px);
  pointer-events: initial;
  position: sticky;
  display: block;
  background-color: #F7F7FC;
  transition: background-color 250ms ease;
  &:hover {
    background-color: #E2EAF5;

  }
}

.plachta_sticky {
  cursor: pointer;
  z-index: 5;
  width: 2000px;
  height: 1400px;
  flex: 0 0 auto;
  margin: 0;
  top: 0px;
  pointer-events: initial;
  position: sticky;
  display: block;
  background-color: #F7F7FC;
  transition: background-color 250ms ease;
  &:hover {
    background-color: #E2EAF5;

  }
}

.plachta_top {
  padding-top: 200px;
  pointer-events: none;
}

