.bbHead {
  width: 100vw;
  background: #141824;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 14px;
}

.bbHead__controls {
  margin-right: auto;
  margin-left: auto;
  height: 100px;
  position: relative;
  max-width: 1520px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

}

.logo {
  margin-top: 30px;
  margin-bottom: 25px;
}

.inputItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-right: 20px;

  span {
    font-size: 14px;
    line-height: 19px;
    color: white;
  }
}
.input__dark {
  margin: 10px 0;
  padding: 5px 10px;
  width: 200px;
  height: 45px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #434650;
  border-radius: 3px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  &:hover,&:active{
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

}

.input__dark_url {
  margin: 10px 0;
  padding: 5px 10px;
  width: 1300px;
  height: 45px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #434650;
  border-radius: 3px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  &:hover,&:active{
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

.button {
  border:0;
  margin: 10px 0;
  background: #006FFF;
  border-radius: 3px;
  width: 200px;
  height: 45px;
  color: #FFFFFF;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  transition: background 250ms ease;
  &:hover {
    background-color: #005fda;
  }
}

.select_wrapper {
  display: inline-block;
  position: relative;
  &::before {
    content: "";
    display:block;
    position: absolute;
    right: 11px;
    top: 30px;
    width: 10px;
    height: 6px;
    z-index: 5;
    background-image: url("/images/icon-dropdown-white.svg");
    pointer-events: none;
  }
}

.select__dark {
  margin: 10px 0;
  padding: 5px 10px;
  width: 200px;
  height: 45px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #434650;
  border-radius: 3px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  appearance: none;
  cursor: pointer;
  padding-right: 29px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  option {
    background: #696b73;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

.setWrapper {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 1520px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.pixelWrapper {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 1520px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.site_content {
  margin: 0 auto;
  width: fit-content;
  max-width: 1200px;
  height: 13000px;
  position: relative;
  visibility: visible;
  background-color: white;
  text-align: center;
  z-index: 10;

  img {
    margin: 30px;
  }
}