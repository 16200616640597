.pixel_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.textarea__dark {
  margin: 10px 0;
  padding: 5px 10px;
  width: 200px;
  height: 129px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #434650;
  border-radius: 3px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  resize: none;
  &:hover,&:active{
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

.input_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-right: 20px;

  span {
    font-size: 14px;
    line-height: 19px;
    color: white;
  }
}


.input__dark_url {
  margin: 10px 0;
  padding: 5px 10px;
  width: 1082px;
  height: 45px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #434650;
  border-radius: 3px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  &:hover,&:active{
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

.select_wrapper {
  display: inline-block;
  position: relative;
  &::before {
    content: "";
    display:block;
    position: absolute;
    right: 11px;
    top: 30px;
    width: 10px;
    height: 6px;
    z-index: 5;
    background-image: url("/images/icon-dropdown-white.svg");
    pointer-events: none;
  }
}

.select__dark {
  margin: 10px 0;
  padding: 5px 10px;
  width: 200px;
  height: 45px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #434650;
  border-radius: 3px;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  appearance: none;
  cursor: pointer;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }


  option {
    background: #696b73;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}