.hidden {
  height: 1px;
  opacity: 0;
}

.upload_video_wrapper {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  height: 180px;
  background: #006FFF;
  transition: background-color 250ms ease;
  &:hover {
    background-color: #005fda;
  }
}

.source_wrapper {
  width: 320px;
  height: 180px;
  z-index: 2;
  video {
    width: 320px;
    height: 180px;
  }
}

.upload_video {
  position: absolute;
  width: 190px;
  height: 140px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: white;
  z-index: 1;
}

.click_area {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
