// RESET
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}

html {
  overflow: visible;
  overflow-x: hidden;
}

body {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
}

img,
fieldset {
  border: 0;
}

img {
  -ms-interpolation-mode: bicubic;
}

abbr,
.help {
  cursor: help;
}

table {
  border-spacing: 0;
}

button,
input[type="submit"]{
  -webkit-appearance: none;
  -moz-appearance: none;
}

button::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
select,
button,
textarea{
  font-family: inherit;
  &:focus{
    outline: 0;
  }
}

// end RESET

//GLOBAL styles
html{
  font-size: 14px;
}

body{
  color: #000;
}

a{
  &:hover {
    cursor: pointer;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type=number] {
  -moz-appearance:textfield;
}

input, button, select {
  transition: all 250ms ease;
}
