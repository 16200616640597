.hidden {
  height: 1px;
  opacity: 0;
}

.upload_image_wrapper {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.bold {
  font-weight: 700;
}

.upload_image {
  position: absolute;
  width: 140px;
  height: 140px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #006FFF;
  z-index: -1;
}

.click_area {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
